import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';

export function msalInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: `${environment.feEnvironmentB2cClientId}`,
      authority: `https://${environment.feEnvironmentB2cTenantName}.b2clogin.com/${environment.feEnvironmentB2cTenantName}.onmicrosoft.com/${environment.feEnvironmentB2cPolicySignUpSignIn}`,
      knownAuthorities: [
        `${environment.feEnvironmentB2cTenantName}.b2clogin.com`,
      ],
      redirectUri: `${environment.feEnvironmentCustomerAppUrl}/`,
      postLogoutRedirectUri: `${environment.feEnvironmentCustomerAppUrl}/`,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true, // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/3486
    },
    system: {
      loggerOptions: {
        loggerCallback: (
          level: LogLevel,
          message: string,
          containsPii: boolean
        ): void => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              return;
            case LogLevel.Info:
              // tslint:disable-next-line:no-console
              return;
            case LogLevel.Verbose:
              // tslint:disable-next-line:no-console
              return;
            case LogLevel.Warning:
              return;
            case LogLevel.Trace:
              return;
            default:
              break;
          }
        },
        logLevel: LogLevel.Verbose,
      },
    },
  });
}

export function msalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap: Map<string, Array<string>> = new Map<
    string,
    Array<string>
  >();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
    'profile',
  ]);
  protectedResourceMap.set(`${environment.feEnvironmentBackendUrl}`, [
    `${environment.feEnvironmentBackendScope}`,
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function msalGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [`${environment.feEnvironmentBackendScope}`],
    },
  };
}
