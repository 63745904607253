import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
// Application Components
import { AppComponent } from './core/components/app/app.component';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  msalGuardConfigFactory,
  msalInstanceFactory,
  msalInterceptorConfigFactory,
} from './auth-config';
import {
  RLM_FEATURE_FLAGS_TO_LOAD_TOKEN,
  RlmAppConfigurationService,
  RlmFeatureFlagService,
  RlmLoggingService,
  RlmMaintenanceInterceptor,
  RlmProfileService,
  RlmSelfDeclarationStateService,
  RlmSystemContext,
} from '@wgi/rlm/common';
import { environment } from 'src/environments/environment';
import { TopicInterceptor } from './main/interceptors/topic.interceptor';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { GasSupplyEmergencyStateService } from './core/services/gas-supply-emergency-state.service';
import { rlmFeatureFlagsToLoad } from './core/constants/rlm-feature-flags-to-load.const';

registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // MSAL
    MsalModule,
    ToastModule,
    ConfirmDialogModule,
  ],
  providers: [
    // custom
    {
      provide: RLM_FEATURE_FLAGS_TO_LOAD_TOKEN,
      useFactory: rlmFeatureFlagsToLoad,
    },
    {
      provide: '_feEnvironmentBackendUrl',
      useValue: `${environment.feEnvironmentBackendUrl}`,
    },
    {
      provide: '_apiUrl',
      useValue: `${environment.feEnvironmentBackendUrl}`,
    },
    {
      provide: '_feEnvironmentLoggingApplicationInsightsKey',
      useValue: `${environment.feEnvironmentLoggingApplicationInsightsKey}`,
    },
    {
      provide: '_showLogging',
      useValue: `${environment.feEnvironmentLoggingConsole}`,
    },
    {
      provide: '_feBrandingContentHelpUrl',
      useValue: '',
    },
    {
      provide: '_rlmSystemContext',
      useValue: RlmSystemContext.CUSTOMER,
    },
    {
      provide: '_isNbb',
      useValue: `${environment.feEnvironmentIsNbb}`,
    },
    {
      provide: '_rlmStage',
      useValue: `${environment.feEnvironmentStage}`,
    },
    {
      provide: '_rlmAppConfigurationReadonlyConnectionString',
      useValue: `${environment.feEnvironmentAppConfigurationReadonlyConnectionString}`,
    },
    {
      provide: '_rlmKeyPrefix',
      useValue: `${environment.feEnvironmentKeyPrefix}`,
    },
    {
      provide: '_pubSubHub',
      useValue: `${environment.feEnvironmentPubSubHub}`,
    },
    // MSAL
    { provide: LOCALE_ID, useValue: 'de-DE' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TopicInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RlmMaintenanceInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: '_msalService',
      useClass: MsalService,
    },
    RlmLoggingService,
    RlmProfileService,
    MessageService,
    DialogService,
    ConfirmationService,
    RlmSelfDeclarationStateService,
    GasSupplyEmergencyStateService,
    RlmAppConfigurationService,
    RlmFeatureFlagService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
