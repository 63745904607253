import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { getTopicUrlsToIntercept } from '../constants/get-topic-urls-to-intercept.const';
import { postTopicUrlsToIntercept } from '../constants/post-topic-urls-to-intercept.const';
import { patchTopicUrlsToIntercept } from '../constants/patch-topic-urls-to-intercept.const';
import { deleteTopicUrlsToIntercept } from '../constants/delete-topic-urls-to-intercept.const';
import { RlmTopicService } from '@wgi/rlm/common';

@Injectable()
export class TopicInterceptor<T> implements HttpInterceptor {
  constructor(private readonly _injector: Injector) {}

  public intercept(
    httpRequest: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    let modifiedRequest: HttpRequest<T>;
    const rlmTopicService: RlmTopicService =
      this._injector.get(RlmTopicService);
    if (this._requestNeedsHandling(httpRequest)) {
      let httpParams: HttpParams = httpRequest.params;
      httpParams = httpParams.append('topicName', rlmTopicService.rlmTopicEnum);
      modifiedRequest = httpRequest.clone({
        params: httpParams,
      });
    }
    // @ts-expect-error no error expected must be parsing issue
    return next.handle(modifiedRequest ?? httpRequest);
  }

  private _requestNeedsHandling(httpRequest: HttpRequest<T>): boolean {
    return (
      this._getRequestNeedsHandling(httpRequest) ||
      this._postRequestNeedsHandling(httpRequest) ||
      this._patchRequestNeedsHandling(httpRequest) ||
      this._deleteRequestNeedsHandling(httpRequest)
    );
  }

  private _getRequestNeedsHandling(httpRequest: HttpRequest<T>): boolean {
    return (
      httpRequest.method === 'GET' &&
      getTopicUrlsToIntercept.some((url: string) =>
        httpRequest.url.includes(url)
      )
    );
  }

  private _postRequestNeedsHandling(httpRequest: HttpRequest<T>): boolean {
    return (
      httpRequest.method === 'POST' &&
      postTopicUrlsToIntercept.some((url: string) =>
        httpRequest.url.includes(url)
      )
    );
  }

  private _patchRequestNeedsHandling(httpRequest: HttpRequest<T>): boolean {
    return (
      httpRequest.method === 'PATCH' &&
      patchTopicUrlsToIntercept.some((url: string) =>
        httpRequest.url.includes(url)
      )
    );
  }

  private _deleteRequestNeedsHandling(httpRequest: HttpRequest<T>): boolean {
    return (
      httpRequest.method === 'DELETE' &&
      deleteTopicUrlsToIntercept.some((url: string) =>
        httpRequest.url.includes(url)
      )
    );
  }
}
