import { Component, OnDestroy } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { primengConfigTranslation } from '../../constants/primeng-config-translation.const';
import { GasSupplyEmergencyStateService } from '../../services/gas-supply-emergency-state.service';
import {
  findAndGetAccessTokenEntryObject,
  RlmPubSubEvent,
  RlmPubSubEventType,
  RlmPubSubService,
  RlmSelfDeclarationStateService,
} from '@wgi/rlm/common';
import { catchError, Subject, tap, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnvironmentService } from '../../../main/services/environment.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  private readonly _ngOnDestroySubject$: Subject<void> = new Subject<void>();
  private readonly _apiUrl: string =
    this._environmentService.environment.feEnvironmentBackendUrl;

  constructor(
    private readonly _config: PrimeNGConfig,
    private readonly _gasSupplyEmergencyStateService: GasSupplyEmergencyStateService,
    private readonly _rlmSelfDeclarationStateService: RlmSelfDeclarationStateService,
    private readonly _environmentService: EnvironmentService,
    private readonly _rlmPubSubService: RlmPubSubService
  ) {
    this._config.setTranslation(primengConfigTranslation);
    this._gasSupplyEmergencyStateService.updateGasSupplyEmergencyData();

    const accessToken: string | undefined =
      <string>findAndGetAccessTokenEntryObject()?.['secret'] ?? undefined;

    if (accessToken) this._subscribeNotifications();
  }

  public ngOnDestroy(): void {
    this._ngOnDestroySubject$.next();
    this._ngOnDestroySubject$.complete();
  }

  private _subscribeNotifications(): void {
    this._rlmPubSubService.notifications$
      .pipe(
        tap((rlmPubSubEvent: RlmPubSubEvent | undefined) => {
          if (rlmPubSubEvent) this._processEvent(rlmPubSubEvent);
        }),
        catchError((err: unknown) => {
          return throwError(() => err);
        }),
        takeUntil(this._ngOnDestroySubject$)
      )
      .subscribe();
  }

  private _processEvent(rlmPubSubEvent: RlmPubSubEvent): void {
    if (!rlmPubSubEvent.eventType) return;

    switch (rlmPubSubEvent.eventType) {
      case RlmPubSubEventType.GAS_SUPPLY_EMERGENCY_STARTED:
      case RlmPubSubEventType.GAS_SUPPLY_EMERGENCY_COMPLETED:
        this._gasSupplyEmergencyStateService.updateGasSupplyEmergencyData();
        break;
      case RlmPubSubEventType.SELF_DECLARATION_PROCESSING_FINISHED:
        this._rlmSelfDeclarationStateService.loadSelfDeclarations(true);
        break;
      case RlmPubSubEventType.SELF_DECLARATION_DELETED:
        this._rlmSelfDeclarationStateService.loadSelfDeclarations(true);
        break;
      case RlmPubSubEventType.ERROR:
        break;
      default:
        break;
    }
  }
}
